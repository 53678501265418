import { ChangeEvent, useId } from "react";
import { useFormContext } from "react-hook-form";

import InputContainer from "@/components/forms/controls/InputContainer";
import { cn } from "@/helpers/className";
import useFormError from "@/hooks/useFormError";

interface SelectProps
  extends Omit<React.ComponentProps<"input">, "id" | "aria-describedby"> {
  hiddenLabel?: boolean;
  inputClassName?: string;
  label: string;
  labelClasses?: string;
  name: string;
  onChange?: (e: ChangeEvent) => void;
  options: { label: string; value: string }[];
}

const Select = ({
  label,
  hiddenLabel = false,
  inputClassName,
  name,
  labelClasses,
  className,
  onChange,
  options,
  ...props
}: SelectProps) => {
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext();
  const error = useFormError(name, errors);
  const id = useId();

  if (onChange) {
    const value = watch(name);
    onChange(value);
  }

  const registerSelect = register(name);

  return (
    <InputContainer
      className={className}
      isError={Boolean(error)}
      error={error}
    >
      <label
        className={cn(
          "mb-2",
          labelClasses,
          hiddenLabel && "sr-only",
          props.required && "label-required",
        )}
        htmlFor={id}
      >
        {label}
      </label>
      <select
        id={id}
        className={cn("form-input cursor-pointer pr-10", inputClassName)}
        {...props}
        {...registerSelect}
        onChange={onChange}
      >
        {options.map(({ label, value }) => (
          <option value={value} key={value}>
            {label}
          </option>
        ))}
      </select>
    </InputContainer>
  );
};

export default Select;
