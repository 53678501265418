"use client";

import { Children, useState } from "react";
import { usePathname } from "next/navigation";

import Icon from "@/components/atoms/Icon";
import { cn } from "@/helpers/className";

interface FilterCategoryProps {
  children: React.ReactNode;
  id?: string;
  title: string;
}

export const FilterCategory = ({
  title,
  children,
  id,
}: FilterCategoryProps) => {
  const [showAll, setShowAll] = useState(false);
  const [closed, setClosed] = useState(false);
  const childCount = Children.count(children);
  const childDisplayLimit = 5;

  const pathname = usePathname();
  const locale = pathname?.split("/").filter(Boolean)?.[0];

  const i18n = {
    nl: {
      show_more: "Toon meer",
      show_less: "Toon minder",
    },
    fr: {
      show_more: "Monter plus",
      show_less: "Monter moins",
    },
    en: {
      show_more: "Show more",
      show_less: "Show less",
    },
  };

  return (
    <div
      className="border-b pb-8 last:border-b-0 last:pb-0 lg:rounded-lg lg:border lg:p-8 lg:last:border-b lg:last:pb-8"
      id={id}
    >
      <div
        className={cn(
          "flex justify-between gap-4",
          !closed && "mb-6 lg:border-b lg:pb-6",
        )}
      >
        <h5 className="!mb-0 font-medium leading-none">{title}</h5>
        <button
          onClick={() => setClosed(!closed)}
          className="-my-2 -mr-2 hidden p-2 lg:block"
          type="button"
        >
          <Icon
            name={"angle-down"}
            className={cn("h-4 w-4", closed && "scale-y-[-1]")}
          />
        </button>
      </div>

      <div
        className={cn(
          "flex flex-col items-start gap-3",
          !showAll &&
            childCount > childDisplayLimit &&
            /* limit-5-children */
            `limit-${childDisplayLimit}-children`,
          closed && "lg:hidden",
        )}
      >
        {children}

        {childCount > childDisplayLimit && (
          <button
            className={"link mt-2 lg:mt-4"}
            onClick={() => setShowAll(!showAll)}
            type="button"
          >
            {showAll ? i18n[locale].show_less : i18n[locale].show_more}
          </button>
        )}
      </div>
    </div>
  );
};
