import Checkbox from "@/components/forms/controls/Checkbox";
import { FilterCategory } from "@/components/molecules/FilterCategory";
import { FacetValue } from "@/graphql/sdk/__generated__";
import { useTranslation } from "@/i18n/client";
import { AllowedLanguages } from "@/types/generic";

interface FilterProps {
  handleCheckboxChange: (name: string, newValue: string[]) => void;
  idPrefix?: string;
  locale: AllowedLanguages;
  name: string;
  selectedFilters: {
    type: string;
    value: string;
  }[];
  values: FacetValue[];
}

export const Filter = ({
  name,
  values,
  handleCheckboxChange,
  selectedFilters,
  idPrefix,
  locale,
}: FilterProps) => {
  const { t } = useTranslation(locale);
  // Remove filters with 0 results
  const filteredValues = values
    ? values.filter((value) => value?.count !== 0)
    : [];

  if (!filteredValues.length) return <></>;

  return (
    <FilterCategory
      title={t(`filter.category.${name}`)}
      id={`${idPrefix ?? ''}filter-${name}`}
    >
      {filteredValues.map((option) => {
        return (
          <Checkbox
            asArray={true}
            label={option?.value ? t(`filter.option.${option.value}`) : ""}
            name={name.toLowerCase()}
            onCheckedChange={handleCheckboxChange}
            selectedFilters={selectedFilters}
            labelSuffix={
              option.count !== undefined ? `(${option.count})` : null
            }
            value={option?.value ?? ""}
            key={option.value}
          ></Checkbox>
        );
      })}
    </FilterCategory>
  );
};
