'use client';

import {ChangeEvent, useEffect, useId, useState} from "react";
import {Combobox} from "@headlessui/react";
import {useFormContext} from "react-hook-form";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

import Icon from "@/components/atoms/Icon";
import {cn} from "@/helpers/className";
import {useGoogleMapsApi} from "@/helpers/googleMapsApi";
import {AllowedLanguages} from "@/types/generic";
import {useTranslation} from "@/i18n/client";
import {Alert} from "@/components/molecules/Alert";

export interface LocationValueSelectProps {
  latitude: number;
  location: string;
  longitude: number;
}

interface NewLocationInputProps {
  allowCurrentLocation?: boolean;
  className?: string;
  i18n: {
    placeholder: string;
    use_current_location: string;
  };
  id?: string;
  inputClassName?: string;
  inputName: string;
  locale: AllowedLanguages
  onSelectValue?: (value: LocationValueSelectProps) => void;
}

export const NewLocationInput = ({
                                   allowCurrentLocation = true,
                                   i18n,
                                   className,
                                   inputClassName,
                                   inputName,
                                   locale,
                                   onSelectValue,
                                   id,
                                 }: NewLocationInputProps) => {
  const {t} = useTranslation(locale);

  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [geoPermissionDenied, setGeoPermissionDenied] = useState(false);
  const {gmapsApiIsLoaded} = useGoogleMapsApi();

  const {watch, setValue: setInputValue, register} = useFormContext();

  const registerInput = register(inputName);
  const randomId = useId();

  const {
    init,
    suggestions: {data},
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    initOnMount: false,
    requestOptions: {
      language: locale,
      region: "be",
      componentRestrictions: {
        country: "be",
      },
      types: ["neighborhood", "locality", "postal_code", "sublocality"],
    },
    debounce: 300,
  });

  const watchInput = watch(inputName) as string;

  // Mainly used to update the query on page load
  useEffect(() => {
    setQuery(watchInput);
  }, [watchInput]);

  useEffect(() => {
    if (gmapsApiIsLoaded) {
      init();
    }
  }, [gmapsApiIsLoaded, init]);

  const transformedData = data.map((suggestion) => {
    const {
      place_id,
      structured_formatting: {main_text, secondary_text},
    } = suggestion;

    return {
      place_id,
      main_text,
      secondary_text,
    };
  });

  const handleInput = (e: ChangeEvent) => {
    const value = (e.target as HTMLInputElement).value.toString() || "";
    // Update query for Google Maps API
    setValue(value);
    setInputValue(inputName, value);
  };

  const handleSelect = (suggestion: string) => {
    setLoading(true);
    const selectedSuggestion = transformedData.find(
      (item) => item.main_text === suggestion,
    );

    if (!selectedSuggestion?.place_id) return false;

    const cityName =
      (parseInt(selectedSuggestion.main_text)
        ? selectedSuggestion.secondary_text
        : selectedSuggestion.main_text) ?? suggestion;

    setValue(cityName, false);
    setInputValue(inputName, cityName);
    setQuery(cityName);

    // Get latitude and longitude via utility functions
    getGeocode({placeId: selectedSuggestion?.place_id})
      .then((results) => {
        const {lat, lng} = getLatLng(results[0]);

        onSelectValue && onSelectValue({
          latitude: lat,
          longitude: lng,
          location: cityName,
        });

        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });

    clearSuggestions();
  };

  const useCurrentLocation = () => {
    setLoading(true);

    navigator.permissions.query({name: 'geolocation'}).then(permissionStatus => {
      if (permissionStatus.state === 'denied') {
        setGeoPermissionDenied(true);
        setLoading(false);
      } else {
        setGeoPermissionDenied(false);

        navigator.geolocation.getCurrentPosition((position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          getGeocode({location: {lat: latitude, lng: longitude}}).then(
            (results) => {
              const cityName = results[0].address_components[2].long_name;
              setValue(cityName, false);
              setInputValue(inputName, cityName);
              setQuery(cityName);

              onSelectValue && onSelectValue({
                latitude: latitude,
                longitude: longitude,
                location: cityName,
              });

              setLoading(false);
            },
          ).catch((error) => {
            console.error(error);
            setValue("");
            setQuery("");
            setInputValue(inputName, "");

            onSelectValue && onSelectValue({
              latitude: latitude,
              longitude: longitude,
              location: "",
            });

            setLoading(false);
          });

        }, (error) => {
          console.error(error);
          if (error.code === 1) {
            setGeoPermissionDenied(true);
          }

          setLoading(false);
        });
      }
    });
  };

  return (
    <div className={cn("flex items-center", className)}>
      <Combobox
        as={"div"}
        className="relative flex w-full"
        onChange={handleSelect}
        value={query ?? ''}
        immediate={allowCurrentLocation}
      >
        <div className="pointer-events-none absolute inset-y-0 left-0 z-[2] flex items-center pl-4">
          <Icon
            name={loading ? "loader" : "map-pin"}
            className={cn("h-5 w-5 text-gray-400", loading && "animate-spin")}
            aria-hidden="true"
          />
        </div>
        <Combobox.Input
          placeholder={i18n.placeholder}
          className={cn("form-input pl-12", inputClassName)}
          autoComplete={"off"}
          onChange={handleInput}
          id={id ?? randomId}
        />
        <input type="hidden" {...registerInput} />
        <Combobox.Options
          className={cn(
            "absolute min-w-[300px] inset-x-0 top-full z-20 max-h-72 scroll-py-2 overflow-y-auto border bg-white px-6 pb-2 pt-4 text-sm text-gray-800 shadow-lg",
            !transformedData.length && "pb-4",
          )}
        >
          {allowCurrentLocation && (
            <>
              <li
                className={cn(
                  "" + "flex items-center gap-2",
                  transformedData.length && "mb-3",
                )}
              >
                <Icon
                  name={"location"}
                  className="h-4 w-4 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                <button
                  type="button"
                  className="link text-sm text-left"
                  onClick={useCurrentLocation}
                >
                  {i18n.use_current_location}
                </button>
              </li>
              {geoPermissionDenied && (
                <li className="mt-2">
                  <Alert
                    type="warning"
                    title={t("geolocation_denied_title")}
                    message={t("geolocation_permission_denied")}
                  />
                </li>
              )}
            </>
          )}
          {transformedData.map(({main_text, secondary_text, place_id}) => (
            <Combobox.Option
              key={place_id}
              value={`${main_text}`}
              className={({active}) =>
                cn(
                  "cursor-pointer select-none border-b py-2 transition-colors last:border-b-0",
                  active && "text-green",
                )
              }
            >
              <strong>{main_text}</strong> <span>{secondary_text}</span>
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </Combobox>
    </div>
  );
};
