import type { Library } from "@googlemaps/js-api-loader";
import { useJsApiLoader } from "@react-google-maps/api";

const API_KEY = process.env.NEXT_PUBLIC_GMAPS_API_KEY as string;
const gmapsLibraries: Library[] = ["places", "geocoding"];

/**
 * Custom hook to load the Google Maps API
 *
 * @returns {boolean} isLoaded - Whether the Google Maps API has been loaded
 */
export const useGoogleMapsApi = () => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: API_KEY,
    libraries: gmapsLibraries,
  });

  return {
    gmapsApiIsLoaded: isLoaded,
  };
};
