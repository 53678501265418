"use client";

import { usePathname, useRouter } from "next/navigation";
import { useFormContext } from "react-hook-form";

import Badge from "@/components/atoms/Badge";
import Icon from "@/components/atoms/Icon";
import { Filter } from "@/components/molecules/Filter";
import type { Facet } from "@/graphql/sdk/__generated__";
import { facetNameToParam } from "@/helpers/search";
import {
  AllowedLanguages,
  FilterType,
} from "@/types/generic";
import {useTranslation} from "@/i18n/client";

interface FiltersProps {
  filterOrder: string[];
  filters: FilterType[] | Facet[];
  i18n: {
    active_filters: string;
    clear_filters: string;
  };
  idPrefix?: string;
  locale: AllowedLanguages;
  selectedFilters: {
    type: string;
    value: string;
  }[];
  updateFilter: (name: string, value: string[] | string) => void;
}

export const Filters = ({
  filters,
  filterOrder,
  i18n,
  idPrefix,
  updateFilter,
  selectedFilters,
  locale,
}: FiltersProps) => {
  const { reset, setValue, getValues } = useFormContext();
  const router = useRouter();
  const pathname = usePathname();
  const { t }  = useTranslation(locale);
  // Note: We can probably get rid of the i18n prop and just use the t function in this component

  const handleCheckboxChange = (name: string, newValue: string[]) => {
    updateFilter(name, newValue);
  };

  const removeFilter = (filter) => {
    const newFilters = selectedFilters.filter((f) => {
      return f.type === filter.type && f.value !== filter.value;
    });
    const newFilterValues = newFilters.map((f) => f.value);

    updateFilter(filter.type, newFilterValues);
    setValue(filter.type, newFilterValues);
  };

  const clearFilters = () => {
    router.push(pathname, { scroll: false });

    const newValues = filterOrder.reduce((acc, filter) => {
      acc[filter] = [];
      updateFilter(filter, []);
      return acc;
    }, {});

    reset(newValues);
  };

  const filtersByKey = [];
  if (filters) {
    filters.forEach((filter) => {
      filtersByKey[facetNameToParam(filter.name)] = filter.values;
    });
  }

  return (
    <>
      {selectedFilters.length > 0 && (
        <div className="mb-4 lg:mb-10">
          <h6>{i18n.active_filters}</h6>

          <div className="mb-3 flex flex-wrap gap-1">
            {selectedFilters.map((filter) => (
              <Badge color={"white"} key={filter.value} className="text-sm">
                <span>
                  {t(`filter.category.${filter.type}`)}: <strong>{t(`filter.option.${filter.value}`)}</strong>
                </span>
                <button
                  type="button"
                  onClick={() => removeFilter(filter)}
                  className="-mx-1 -mr-2 p-1"
                >
                  <Icon name={"close"} className="h-3 w-3" />
                </button>
              </Badge>
            ))}
          </div>

          <button
            onClick={clearFilters}
            className="btn btn-sm btn-green"
            type="button"
          >
            {i18n.clear_filters}
          </button>
        </div>
      )}

      <div className="flex flex-col gap-6">
        {filterOrder.map((key) => (
          <Filter
            name={key}
            values={filtersByKey[key]}
            handleCheckboxChange={handleCheckboxChange}
            selectedFilters={selectedFilters}
            key={key}
            idPrefix={idPrefix}
            locale={locale}
          />
        ))}
      </div>
    </>
  );
};
