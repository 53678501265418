import Image from "next/image";

import {Share} from "@/components/organisms/Share";
import {
  ImageFieldsFragment,
  ImageStyleAvailable,
} from "@/graphql/sdk/__generated__";
import {cn} from "@/helpers/className";
import {getVariation} from "@/helpers/image";
import Sprite from "@/components/atoms/Sprite";
import SaveJobButton from "@/components/molecules/SaveJobButton";

interface HeroProps {
  className?: string;
  image?: {
    url: string;
  };
  media?: ImageFieldsFragment;
  noSprite?: boolean;
  saveJobId?: string;
  share?: boolean;
  small?: boolean;
  title?: string;
  titleClassName?: string;
  titleLeft?: boolean;
}

export const Hero = ({
                       media,
                       image,
                       title,
                       titleClassName,
                       className,
                       share,
                       small,
                       titleLeft,
                       noSprite,
                       saveJobId,
                     }: HeroProps) => {
  if (!image) {
    image = media?.variations
      ? getVariation(media.variations, ImageStyleAvailable.Hero)
      : undefined;
  }

  return (
    <div
      className={cn(
        "default-hero relative z-0 mb-10 mt-0 flex min-h-[215px] items-end px-4 justify-center md:min-h-[400px] lg:mb-24 xl:min-h-[440px] 2xl:min-h-[550px] pt-10",
        !image && "bg-yellow-600 overflow-hidden relative",
        small ? "min-h-[150px] md:min-h-[300px] xl:min-h-[300px]" : "",
        className,
      )}
      style={image && {backgroundColor: "rgba(0, 0, 0, 0.3)"}}
    >
      {image ? (
        <Image
          className="inset-0 -z-1 h-full w-full object-cover mix-blend-multiply xl:absolute"
          src={image.url}
          alt={media?.alt ?? title ?? ''}
          fill
          loading={"eager"}
          priority
        />
      ) : (
        <>
          {!noSprite && (
            <Sprite className="w-[517px] right-0 top-1/2 mt-12 -translate-y-1/2 translate-x-[66px] transition-none"/>
          )}
        </>
      )}

      <div
        className={cn(
          "container flex flex-col gap-8 relative z-1",
          !titleLeft && "md:items-center",
        )}
      >
        <div
          className="flex justify-center gap-2 empty:hidden items-center mx-auto">
          {saveJobId && (
            <SaveJobButton
              jobId={saveJobId}
              className="aspect-square w-12 lg:w-16 border-2 border-white/50 rounded-full p-0 m-0 top-0 hover:scale-100 !text-white flex justify-center items-center hover:!text-black hover:border-black"
            />
          )}

          {share && (
            <Share
              title={title ?? ''}
              className=""
            />
          )}
        </div>

        {title && (
          <h1
            className={cn(
              "lg:!mb-20 text-center break-words",
              image && "text-white",
              titleClassName,
            )}
          >
            {title}
          </h1>
        )}
      </div>
    </div>
  );
};
